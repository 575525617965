.documentSelect {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0px 20px 20px;
}
:global(.seamless) .documentSelect {
  padding: 0;
}

.title {
  margin-bottom: 8px;
}

@media (max-width: 768px) {
  .title {
    margin-bottom: 20px;
  }
}

:global(.seamless) .title {
  font-size: 24px;
  font-weight: 500px;
}

:global(.seamless) .toggleBox {
  padding: 0 2px;
  margin: 32px 0;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0 0;
}

:global(.seamless) .buttonContainer {
  flex-direction: column;
  justify-content: flex-start;
}

:global(.seamless) .goBack {
  margin-top: 20px;
}

[data-page-layout='portrait'] .documentSelect {
  flex: 1;
}
