.toggleBox {
  flex: 1;
  margin-top: 20px;
}

@media (max-width: 480px) {
  .toggleBox :global(.ToggleBoxItem-area) {
    padding: 12px 16px;
  }
}

:global(.seamless) .toggleBox {
  padding: 0 2px;
  margin: 8px 0 20px 0;
}
