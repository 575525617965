@value constants: '../../styles/constants.module.css';
@value mobileBreakpoint from constants;

@media (max-width: mobileBreakpoint) {
  .feedbackDialog {
    max-width: 90vw;
  }

  .feedbackFooter > :global(.Box-root) {
    flex-direction: column-reverse;
  }
}
