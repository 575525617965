.welcomeConsent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 12px 20px 20px;
  flex-grow: 1;
}

@media (max-width: 768px) {
  .welcomeConsent {
    padding: 20px;
  }
}

:global(.seamless) .welcomeConsent {
  padding: 0px;
}

.title {
  margin-bottom: 8px;
}

:global(.seamless) .title {
  font-size: 24px;
  font-weight: 500px;
  margin-bottom: 12px;
}

.welcomeConsent p {
  padding: 0;
}

.welcomeConsent p.informed {
  font-size: 90%;
}

:global(.seamless) .welcomeConsent p {
  font-size: 16px;
}

:global(.seamless) .welcomeConsent p.informed {
  font-size: 14px;
}

.welcomeConsent .welcomeConsentDevButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

@media (max-width: 768px) {
  .welcomeConsent .welcomeConsentDevButton {
    position: fixed;
    top: 2vh;
    bottom: initial;
    right: 2vh;
  }
}

.actions {
  margin-top: 20px;
}

.buttonContainer > * {
  margin-bottom: 12px;
}

.buttonContainer > *:last-child {
  margin-bottom: 0;
}
