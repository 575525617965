@value constants: '../styles/constants.module.css';
@value mobileBreakpoint, titleHeight, pageCardSingleWidth, pageCardDoubleWidth from constants;

.pageCard {
  height: 100%;
}

:global(.seamless) {
  max-width: 480px;
}

.pageCardRoot {
  --page-card-border-radius: 4px;
  height: 100%;
  position: relative;
  z-index: 1;
}

.pageCardContainer {
  width: auto;
  height: 100%;
  padding-top: 112px;
}

:global(.seamless) .pageCardContainer {
  padding-top: 0;
}

@media (max-width: mobileBreakpoint) {
  .pageCardContainer {
    padding-top: 0;
    width: 100%;
  }
}

.card {
  display: flex;
  flex-direction: column;
  min-height: 430px;
}

/* Use ".card.card" to supersede ".card-root" from Sails. */
.card.card {
  overflow: inherit;
}

:global(.seamless) .card {
  min-height: auto;
  background-color: transparent;
  overflow: hidden !important;
}

.card.cardWithHeader {
  overflow: visible;
}

@media (max-width: mobileBreakpoint) {
  .card {
    height: 100%;
  }
}

:global(.Container--mobile) {
  width: 100%;
}

:global(.Container--double) :global(.InnerCard) {
  padding-top: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.innerCard {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  border-bottom-left-radius: var(--page-card-border-radius);
  border-bottom-right-radius: var(--page-card-border-radius);
  overflow: visible;
}

@media (max-width: mobileBreakpoint) {
  .innerCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media (max-width: mobileBreakpoint) {
  .innerCardWithHeader {
    height: calc(100% - titleHeight);
  }
}

:global(.seamless) .innerCard {
  /* This is to prevent the page from collapsing completely during transitions */
  min-height: 294px;
  /* This prevents cropping the drop shadow around buttons */
  padding: 0 2px 2px;
}

.titleContainer {
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  border-radius: var(--page-card-border-radius) var(--page-card-border-radius) 0
    0;
  width: 100%;
  background-color: transparent;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin: 0;
  margin-right: 16px;
  width: 100%;
}

:global(.seamless) .titleContainer {
  margin: 0;
  margin-right: 10px;
}

.titleLeft {
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.backLink.backLink {
  margin: 4px 0;
  padding: 4px 20px;
  width: fit-content;
}

:global(.seamless) :global(.ToggleBoxItem-label) {
  font-size: 16px;
}

:global(.PressableButton.Button) {
  max-width: 100%;
}

:global(.PressableButton .Button-element) {
  min-width: 96px;
}

:global(.seamless) :global(.PressableButton.Button) {
  max-width: 480px;
  width: 100%;
}

:global(.seamless) :global(.PressableButton .Button-element) {
  padding: 8px 12px;
}

:global(.seamless) :global(.Button-label) {
  font-size: 16px;
}

:global(.Container--single) {
  width: pageCardSingleWidth;
}

:global(.Container--double) {
  width: pageCardDoubleWidth;
}

.pageCardContent {
  display: contents;
}

.pageCardContentHeightFill {
  display: flex;
  flex: 1 1 auto;
}

/* Desktop layout */

[data-page-layout='portrait'] .card.cardWithHeader {
  overflow: visible;
}

[data-page-layout='portrait'] :global(.Container--single),
[data-page-layout='portrait'] :global(.Container--double) {
  width: auto;
}

[data-page-layout='portrait'] .pageCardRoot {
  --page-card-border-radius: 10px;
}

[data-page-layout='portrait'] .pageCardContainer {
  height: auto;
}

[data-page-layout='portrait'] .card {
  border-radius: var(--page-card-border-radius);
  height: var(--page-layout-height);
  min-height: var(--page-layout-min-height);
  width: var(--page-layout-width);
}

[data-page-layout='portrait'] .innerCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Mobile layout */
@media (max-width: mobileBreakpoint) {
  [data-page-layout='portrait'] :global(.seamless) {
    /*
     * The card needs the min-height to prevent the iframe from collapsing
     * when the UI is seamless.
     */
    min-height: 480px;
  }

  [data-page-layout='portrait'] :global(.Container--mobile) {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  [data-page-layout='portrait'] .pageCardRoot {
    --page-card-border-radius: 0;
  }

  [data-page-layout='portrait'] .pageCardContainer {
    height: var(--page-layout-height);
  }

  [data-page-layout='portrait'] .card {
    display: flex;
    overflow: hidden;
  }

  [data-page-layout='portrait'] .card.cardWithHeader {
    overflow: hidden;
  }

  [data-page-layout='portrait'] .innerCard {
    /* On mobile layout, this defines the scrollable area.  */
    overflow: auto;
  }

  [data-page-layout='portrait'] .pageCardContent {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}
