.consentDeclinedPage {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.pageHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

:global(.seamless) .pageHeader {
  padding: 20px 0;
}

.title {
  margin-left: 0.62ch;
}

.pageContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  height: 100%;
  padding: 0 20px;
  text-align: center;
}

:global(.seamless) .pageContent {
  padding: 0;
}

.pageButtons {
  padding: 20px;
}

:global(.seamless) .pageButtons {
  padding: 0;
}

.pageButtons > * {
  margin-bottom: 12px;
}

.pageButtons > *:last-child {
  margin-bottom: 0;
}
