.landscapeMessageContainer {
  display: none;
}

.landscapeMessage {
  padding-bottom: 12vh;
}

@media screen and (min-width: 480px) and (min-aspect-ratio: 1.6) {
  .landscapeMessageContainer {
    z-index: 10000;
    position: fixed;
    display: grid;
    width: 100vw;
    height: 100vh;
    place-content: center;
    background-color: #fff;
    font-size: 20px;
    top: 0;
  }
}
