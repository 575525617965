.verifyOptions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  height: 100%;
  padding: 12px 20px 20px;
}

@media (max-width: 768px) {
  .verifyOptions {
    padding: 20px;
  }
}

:global(.seamless) .verifyOptions {
  padding: 0;
}

:global(.seamless) .title {
  font-size: 24px;
  font-weight: 500px;
}

.unsupportedNotice {
  margin-top: 20px;
}

.verifyOptions .toggleBox {
  flex: 1;
  margin-top: 20px;
}

:global(.seamless) .toggleBox {
  padding: 0 2px;
  margin: 20px 0;
}

.ctaButtonContainer {
  margin-top: 20px;
}

:global(.seamless) .ctaButtonContainer {
  display: flex;
}
