@value constants: '../styles/constants.module.css';
@value mobileBreakpoint from constants;

.ctaButton {
  min-width: 96px;
}

@media only screen and (max-width: mobileBreakpoint) {
  .ctaButton {
    max-width: none;
    width: 100%;
  }

  .ctaButton :global(.Button-label) {
    white-space: normal;
  }
}
