@value constants: '../styles/constants.module.css';
@value mobileBreakpoint, pageCardSingleWidth, transitionDuration from constants;

.gridWrapper {
  z-index: -1;
  position: absolute;
  display: block;
  content: '';
  min-height: 100%;
  width: 100%;
  overflow: hidden;
}

@media (max-width: mobileBreakpoint) {
  .gridWrapper {
    display: none;
  }
}

.gridContainer {
  z-index: -1;
  position: relative;
  width: 100%;
  height: 100%;
}

.grid {
  z-index: -1;
  position: absolute;
  top: -300px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 742px;
  pointer-events: none;
  background: #a3acb9;
  transform: skewY(-12deg);
}

.backgroundAlt {
  z-index: -2;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: #f7fafc;
  transform: skewY(-12deg) translateY(180px);
}

.gridBackground {
  position: absolute;
  top: 0;
  width: 100%;
  height: 550px;
}

.stripe,
.ghostStripe {
  position: absolute;
  max-width: pageCardSingleWidth;
  width: 100%;
  height: 84px;
}

.stripe1 {
  bottom: 0;
  transition: background-color transitionDuration ease-in-out;
  left: calc(50% - (pageCardSingleWidth / 2) - 80px);
}

.stripe2 {
  bottom: 0;
  transition: background-color transitionDuration ease-in-out;
  left: calc(50% - (pageCardSingleWidth / 2) - 280px);
}

.stripe3 {
  bottom: 0;
  transition: background-color transitionDuration ease-in-out;
  left: calc(50% - (pageCardSingleWidth / 2) + 280px);
}

.grid .ghostStripe {
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.15);
  max-width: 160px;
}

.ghostStripe1 {
  bottom: 128px;
  left: calc(50% - (pageCardSingleWidth / 2) - 80px);
}

.ghostStripe2 {
  bottom: 0px;
  left: calc(50% + (pageCardSingleWidth / 2) - 80px);
}

[data-page-layout='portrait'] {
  background-color: #fff;
}

[data-page-layout='portrait'] .Background {
  /* This creates the slanted background image. */
  /* See example at https://jsfiddle.net/stripehedger/zkrcv6o8/22/ */
  /* Use float number (e.g 54.9) to create anti-alias effect */
  background-image: linear-gradient(
    -15deg,
    var(--page-background-color) 54.9%,
    #fff 55%
  );
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  min-height: var(--page-layout-min-height);
  pointer-events: none;
  position: fixed;
  width: 100vw;
  z-index: -1;
}

@media (max-width: mobileBreakpoint) {
  [data-page-layout='portrait'] .Background {
    display: none;
  }
}
