@value constants: '../styles/constants.module.css';
@value darkBackgroundTransparent, mobileBreakpoint, pageCardHeight from constants;

.pageModalRoot {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
}

.pageModal {
  animation: fadeIn 150ms;
  background-color: rgba(0,0,0,0);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100vh;
  left: 0;
  overflow: auto;
  padding: 24px;
  padding-top: 112px; /* This value is copied from .pageCardContainer */
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 1;
}

.withBackDrop {
  background-color: rgba(193, 201, 210, .7);
}

.pageModalBody {
  align-items: center;
  animation: zoomIn 150ms;
  display: flex;
  justify-content: center;
  min-height: pageCardHeight;
}

@media (max-width: mobileBreakpoint) {
  .pageModal {
    padding-top: 0;
    justify-content: center;
  }
}

@keyframes fadeIn {
	0% {opacity: 0;}
	100% {opacity: 1;}
}

@keyframes zoomIn {
	0% {transform: scale(0.8);}
	100% {transform: scale(1);}
}
