body,
html,
:global(#__next) {
  margin: 0;
  height: 100%;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
  sans-serif;
}

* {
  box-sizing: border-box;
}

@keyframes :global(fadein) {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

:global(.Dialog-size--small),
:global(.Dialog-size--medium),
:global(.Dialog-size--large),
:global(.Dialog-size--xlarge) {
  max-width: 90vw;
}

@media (max-width: 480px) {
  :global(.Dialog) {
    min-width: 240px !important;
  }
}

::-webkit-scrollbar {
  display: none;
}


/*
 * This fixes the bug that button isn't clickable on Android Firefox.
 * This happens because Sails uses "appearance: none" to clear button's styles,
 * which creates the side-effect that the text inside button becomes selectable.
 * If text selection is allowed, Android Firefox would start the text selection
 * mode and the button is no longer clickable when user clicks the button.
 */
button, button > div {
  user-select: none;
}
