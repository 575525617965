.background {
  z-index: 2;
  position: relative;
  overflow: hidden;
  border: 2px solid #ffffff;
  border-radius: 50%;
  box-shadow: 0 15px 35px 0 rgba(60, 66, 87, 0.12), 0 5px 15px 0 rgba(0, 0, 0, 0.12);
  width: 68px;
  height: 68px;
  background: #f7fafc;
}

.platformIcon {
  width: 100%;
  height: 100%;
}
