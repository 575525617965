@value constants: '../styles/constants.module.css';
@value mobileBreakpoint from constants;

[data-page-layout='portrait'] .TestingPage {
  height: 100%;
  overflow: auto;
}

@media (max-width: mobileBreakpoint) {
  [data-page-layout='portrait'] .TestingPage {
    height: auto;
  }
}
