.consentDevTools {
  z-index: 400;
  position: fixed;
  top: 4vh;
  left: 4vh;
}

@media (max-width: 768px) {
  .consentDevTools {
    top: 10vh;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100vw - 24px);
  }
}
