/* Styles for page tranitions */
/* See gelato/frontend/src/lib/pageTransitions.ts for implementation details */

.withPageTransitionAnimation {
  /* Transiton used for new Butter pages. */
  view-transition-name: page-transition-element;
}

.withoutPageTransitionAnimation {
  /* This is a dummy transition name that does not play any animation */
  view-transition-name: no-transition;
}

:root {
  --page-transition-distance: 10px;
  --page-transition-duration: 350ms;
}

/** Specify the name of the animation that pulls the new page in. **/
:root[data-page-transition-direction='center']:root::view-transition-new(page-transition-element) {
  animation-name: page-transition-fade-in;
}

/** Specify the name of the animation that pulls the old page out. **/

:root[data-page-transition-direction='center']:root::view-transition-old(page-transition-element) {
  animation-name: page-transition-fade-out;
}

/** Specify the name of the animation that pulls the new page in. **/
:root[data-page-transition-direction='forward']:root::view-transition-new(page-transition-element) {
  animation-name: page-transition-forward-in;
}

/** Specify the name of the animation that pulls the old page out. **/
:root[data-page-transition-direction='forward']:root::view-transition-old(page-transition-element) {
  animation-name: page-transition-forward-out;
}

/** Specify the name of the animation that pulls the new page in. **/
:root[data-page-transition-direction='backward']::view-transition-new(page-transition-element) {
  animation-name: page-transition-backward-in;
}
/** Specify the name of the animation that pulls the old page out. **/
:root[data-page-transition-direction='backward']::view-transition-old(page-transition-element) {
  animation-name: page-transition-backward-out;
}

:root::view-transition-new(page-transition-element),
:root::view-transition-old(page-transition-element) {
  animation-duration: var(--page-transition-duration);
  animation-timing-function: ease-out;
  mix-blend-mode: normal;
}

@keyframes page-transition-fade-in {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes page-transition-fade-out {
  0% {
    opacity: 1;
  }

  75% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes page-transition-forward-in {
  0% {
    opacity: 0;
    transform: translate3d(var(--page-transition-distance), 0, 0);
  }

  50% {
    opacity: 0;
    transform: translate3d(var(--page-transition-distance), 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes page-transition-forward-out {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  50% {
    opacity: 0;
    transform: translate3d(calc(var(--page-transition-distance) * -1), 0, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d(calc(var(--page-transition-distance) * -1), 0, 0);
  }
}

@keyframes page-transition-backward-in {
  0% {
    opacity: 0;
    transform: translate3d(calc(var(--page-transition-distance) * -1), 0, 0);
  }

  50% {
    opacity: 0;
    transform: translate3d(calc(var(--page-transition-distance) * -1), 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes page-transition-backward-out {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  50% {
    opacity: 0;
    transform: translate3d(var(--page-transition-distance), 0, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d(var(--page-transition-distance), 0, 0);
  }
}
