.testModeBanner {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 6;
  border-top: 1px solid #f5925e;
  border-radius: 0;
  width: 100%;
  text-align: center;
  line-height: 0;
  /* Allow clicks to go through the banner in places where the banner covers UI elements such as tabs */
  pointer-events: none;
}

:global(.seamless) .testModeBanner {
  position: relative;
}

.testModeBannerContent {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  border-radius: 0 0 4px 4px;
  height: 14px;
  padding: 2px 4px;
  background-color: #f5925e;
  color: white;
  font-size: 10px;
  line-height: 10px;
  font-weight: 700;
}