.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  padding: 16px 20px 16px;
}

.leftSide,
.rightSide {
  display: flex;
  align-items: center;
}

.backLinkContainer {
  width: 28px;
  height: 28px;
  padding: 4px 8px 4px 0;
}

.verticalDivider {
  margin-right: 16px;
  border-right: 1px solid #c1c9d2;
  height: 28px; /* height of the brand icon */
  width: 1px;
}

.platformIcon {
  margin-right: 8px;
  border-radius: 50%;
  width: 28px;
  height: 28px;
}

.lockIcon {
  padding-right: 8px;
}

[data-page-layout='portrait'] .container {
  padding: 16px 20px 12px 20px;
  width: 100%;
}
