@value constants: '../styles/constants.module.css';
@value mobileBreakpoint, titleHeight, pageCardSingleWidth, pageCardDoubleWidth from constants;

.PageLayout {
  /*
   * By default, this element should have no box representation in the layout,
   * meaning that the element itself effectively disappears from the layout
   * tree.
   * Instead, its children behave as if they were direct children of the
   * element's parent. As a result, any visual styles applied to the element
   * itself are not rendered, but its child elements still inherit styles from
   * it.
   * This allows .PageLayout to be a pure structural container without impacting
   * the layout of the components within.
   */
  display: contents;
}

[data-page-layout='portrait'] {
  --page-layout-height: auto;
  --page-layout-max-height: var(--page-layout-viewport-height);
  --page-layout-min-height: 600px;
  --page-layout-width: 400px;
}

[data-page-layout='portrait'] body {
  height: auto;
}

@media (max-width: mobileBreakpoint) {
  [data-page-layout='portrait'] {
    --page-layout-height: var(--page-layout-viewport-height);
    --page-layout-max-height: unset;
    --page-layout-min-height: unset;
    --page-layout-width: var(--page-layout-viewport-width);
  }

  [data-page-layout='portrait'] body {
    height: 100%;
    overflow: auto;
    overscroll-behavior: none;
  }
}
