.successPage {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.pageHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.title {
  margin-left: 0.62ch;
}

.pageContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  height: 100%;
  padding: 0 20px;
  text-align: center;
}

.pageButtons {
  padding: 20px;
}
