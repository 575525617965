@value constants: '../../styles/constants.module.css';
@value mobileBreakpoint from constants;

.footer {
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 16px 16px;
  flex-shrink: 0;
}

@media (max-width: 1024px) {
  .footer {
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 16px 0;
  }
}

.footerRow {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  margin-right: 12px;
}

@media (max-width: 1024px) {
  .footerRow {
    margin-bottom: 12px;
    margin-right: 0;
  }
}

.footerRow > * {
  margin-right: 12px;
}

.footerRow > *:last-child {
  margin-right: 0;
}

.poweredBy {
  margin-right: 4px;
}

.footerLinkWrapper {
  margin-right: 12px;
}

.footerLinkWrapper:last-of-type {
  margin-right: 0;
}

/* Desktop layout *************************************************************/
[data-page-layout='portrait'] .footer {
  /* The UI elements at the footer should always be gray and brighter. */
  align-items: center;
  bottom: 20px;
  display: flex;
  filter: saturate(0%) brightness(130%);
  flex-direction: row;
  left: 0;
  padding: 30px 0 0 0;
  position: fixed;
  right: 0;
}

[data-page-layout='portrait'] .stripeLogo {
  /* This applies a CSS filter to darken the logo color from #BBBBBB to
     match the text color #939393. */
  filter: brightness(78.61%);
}

@media (max-height: 840px), (max-width: mobileBreakpoint) {
  [data-page-layout='portrait'] .footer {
    position: static;
  }
}

[data-page-layout='portrait'] .stripeLogo {
  margin: 0 -0.2rem;
  transform: scale(0.8) translateY(1px);
}

[data-page-layout='portrait'] .footerRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0.2rem;
}

[data-page-layout='portrait'] .footerRow + .footerRow::before {
  /* The vertical divider. */
  background-color: #87909f;
  content: '';
  display: inline-block;
  height: 1rem;
  margin-right: 0.5rem;
  vertical-align: middle;
  width: 1px;
}

/* Mobile layout **************************************************************/
@media (max-width: mobileBreakpoint) {
  [data-page-layout='portrait'] .footer {
    flex-direction: column;
    padding: 1rem;
  }

  [data-page-layout='portrait'] .footerRow + .footerRow::before {
    /* Hide the vertical divider. */
    display: none;
  }
}
