.scrollableMenu {
  overflow-y: scroll;
  max-height: 62vh;
  padding: 12px;
}

.scrollableMenu ::-webkit-scrollbar {
  display: auto;
}

.scrollableMenu > * {
  margin-bottom: 12px;
}

.scrollableMenu > *:last-of-type {
  margin-bottom: 0;
}
